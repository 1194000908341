import React from "react";

import {Card, Badge } from "react-bootstrap";
import {Link} from "react-router-dom";

function EventTile({theEvent, theUser}) {

    //Booleans to simplify rendering logic below.
    const isMyEvent = theEvent.event_user.event_user_id === theUser.userID;
    const isPending = theEvent.event_status === "P";

    return (

        (isMyEvent || theUser.userType.toUpperCase() === "ADMIN") ? (

            <Link to = "/handleevent" state = {{selectEvent: theEvent}} style={{ textDecoration: 'none', color: 'inherit'}}>
        
                <Card className="mb-2 h-100" bg="light" border= {isPending ? "info" : "none"} style={{ maxHeight: '35px' }}>

                    <Card.Body className="d-flex justify-content-center align-items-center h-100">

                        <p className="m-0" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%' }}>
                            {theEvent.event_name}
                        </p>

                        <Badge pill bg= {isMyEvent ? "success" : "info"} className="ms-2 custom-badge">{theEvent.event_user.event_user_badge}</Badge>

                    </Card.Body>

                </Card>

            </Link>

        ) : (

            <Card className="mb-2 h-100" bg="light" border= {isPending ? "info" : "none"} style={{ maxHeight: '35px' }}>

                <Card.Body className="d-flex justify-content-center align-items-center h-100">

                    <p className="m-0" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%' }}>
                        {theEvent.event_name}
                    </p>

                    <Badge pill bg= "secondary" className="ms-2 custom-badge">{theEvent.event_user.event_user_badge}</Badge>

                </Card.Body>

            </Card>
        )
    )
    
};

export default EventTile;