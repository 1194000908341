import React from "react";

function About() {

    return (
        <div>
            <h3>About the App</h3>
            <p>Created to manage the use of Mum + Dad Campbell's Leo St Beach House. Happy Fishing.</p>
        </div>
    );

}

export default About;